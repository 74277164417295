import React from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Divider, SvgIconProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import NavigationTab from './NavigationTab';
import { routes, secondaryRoutes } from './routes';
import { useFeatureFlags } from '../../../FeatureFlags';
import Icon, { AvailableIconTypes } from '../../../Icon';
import { useIsEnterpriseUser } from '../../../../context/user/UserContext';

export function getBasePathFromRoute(routePath: string): string {
  return routePath.split('/').slice(0, 3).join('/');
}

export const getTabIcon =
  (iconType: AvailableIconTypes) =>
  ({ className, fontSize }: SvgIconProps) => {
    return <Icon type={iconType} className={className} fontSize={fontSize} />;
  };

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  list: {
    transition: theme.transitions.create(['padding'], {
      easing: 'cubic-bezier(0, 0, 0.2, 1)',
      duration: 800,
    }),
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '16px 0px',
  },
  section: {
    ...theme.mixins.scrollBar,
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '100%',
    scrollbarGutter: 'stable',
  },
}));

const NavigationTabs = ({
  isLeftRailOpen,
  className,
}: {
  isLeftRailOpen: boolean;
  className: string;
}) => {
  const classes = useStyles({ isLeftRailOpen });

  const isEnterprise = useIsEnterpriseUser();

  const { isFeatureEnabled } = useFeatureFlags();

  const isEnterpriseWorkspacesEnabled =
    isEnterprise && isFeatureEnabled('custom-workspaces');
  const mainNavigationTabs = routes.filter(item => {
    const { featureFlag } = item;
    if (featureFlag && !isFeatureEnabled(featureFlag)) {
      return false;
    }
    /**
     * Hide new landing page if user is not enterprise
     */
    if (
      item.route === '/dashboard/workspaces' &&
      !isEnterpriseWorkspacesEnabled
    ) {
      return false;
    }
    /**
     * If user has access, remove old projects tab in favor of landing page
     */
    if (item.route === '/dashboard/projects' && isEnterpriseWorkspacesEnabled) {
      return false;
    }
    return true;
  });

  const secondaryNavigationTabs = secondaryRoutes.filter(item => {
    const { featureFlag } = item;
    if (featureFlag && !isFeatureEnabled(featureFlag)) {
      return false;
    }
    return true;
  });

  return (
    <div className={clsx(classes.section, className)}>
      <NavigationMenu.Root
        orientation="vertical"
        className={classes.root}
        aria-label="Main"
      >
        <NavigationMenu.List className={classes.list}>
          {mainNavigationTabs.map((tab, index) => (
            <NavigationTab
              label={tab.label}
              href={tab.route}
              Icon={getTabIcon(tab.iconType)}
              isLeftRailOpen={isLeftRailOpen}
            />
          ))}
        </NavigationMenu.List>
        <Divider />
        <NavigationMenu.List className={classes.list}>
          {secondaryNavigationTabs.map((tab, index) => (
            <NavigationTab
              label={tab.label}
              Icon={getTabIcon(tab.iconType)}
              isLeftRailOpen={isLeftRailOpen}
              href={tab.route}
              target="_blank"
            />
          ))}
        </NavigationMenu.List>
      </NavigationMenu.Root>
    </div>
  );
};
export default NavigationTabs;
